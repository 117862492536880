<template>
  <div>
    <app />
  </div>
</template>

<script>
import App from '../_layouts/App.vue';

export default {
  name: 'uello-app',
  components: {
    App,
  },
};
</script>

<style></style>
