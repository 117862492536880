<template>
  <header
    class="uello-app-header py-3 px-3 w-100 d-flex"
    :class="$route.meta.title ? 'justify-content-between' : 'justify-content-end'"
  >
    <h2 class="text-dark-darken font-weight-bold uello-app-header__title" v-if="$route.meta.title">
      {{ $route.meta.title }}
      <a
        :href="$route.meta.urlLink"
        target="_blank"
        style="margin-left: 10px"
        v-if="$route.meta.hasIcon"
      >
        <icon
          :icon="$route.meta.icon"
          :title="$route.meta.description"
          :size="25"
          color="var(--info)"
        />
      </a>
    </h2>
    <b-dropdown variant="link" right no-caret toggle-class="text-decoration-none">
      <template v-slot:button-content>
        <h5 class="text-primary font-weight-bold text-right">
          {{ getPartner }}
          <span class="icon-span" :title="$t('general.exit')">
            <icon icon="exit-to-app" :size="20" color="var(--primary)" right />
            <br />
            <!--span class="icon-text">{{ $t('general.exit') }}</!--span-->
          </span>
        </h5>
      </template>
      <b-dropdown-item @click="setLogout">{{ $t('general.logOut') }}</b-dropdown-item>
    </b-dropdown>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as types from '@/store/types';
import { getPartner, safeStorage } from '@/utils';

const { movidesk_chat_key } = safeStorage.getItem('config');
export default {
  name: 'uello-app-header',
  computed: {
    ...mapGetters({
      getIsAuthenticated: types.GETTER_IS_AUTHENTICATED,
    }),
    getPartner() {
      return getPartner();
    },
  },
  methods: {
    ...mapActions({
      setLogout: types.ACTION_RESET_DATA,
    }),
  },
  watch: {
    getIsAuthenticated(value) {
      if (!value) {
        this.$router.push('/');
      }
    },
  },
  mounted() {
    const chat = document.createElement('script');
    const key = document.createElement('script');

    chat.setAttribute('src', 'https://chat.movidesk.com/Scripts/chat-widget.min.js');
    key.text = `var mdChatClient="${movidesk_chat_key}";`;
    document.head.appendChild(key).appendChild(chat);
  },
};
</script>

<style lang="scss">
.uello-app-header {
  position: fixed;
  height: 62px;
  top: 0;
  right: 0;
  z-index: 15;
  background: #ffffff;

  button {
    padding: 0;
  }

  &__title {
    margin-left: 62px;

    &--link {
    }

    &--icon {
    }
  }

  .icon-span {
    position: relative;
    top: 0px;
  }

  .icon-text {
    top: -4px;
    font-size: 12px;
    position: relative;
  }
}
</style>
