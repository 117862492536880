<template>
  <div
    class="uello-sidebar d-flex flex-column bg-primary h-100"
    :class="{ hovered: isActive }"
    @mouseenter="isActive = true"
    @mouseleave="isActive = false"
  >
    <div class="logo-container px-2 py-3">
      <router-link to="/app/map" :title="$t('appTitle')">
        <svg xmlns="http://www.w3.org/2000/svg" width="246" height="60" viewBox="0 0 246 60" v-bind:svg-inline="''" :class="`uello-logo ${isActive ? 'active' : ''}`" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#fff"><path d="M216.03 53.357c-12.983 0-23.545-10.51-23.545-23.43 0-12.922 10.562-23.433 23.545-23.433 12.983 0 23.546 10.51 23.546 23.432 0 12.92-10.563 23.431-23.546 23.431m0-53.144c-16.462 0-29.855 13.329-29.855 29.713 0 16.383 13.393 29.712 29.855 29.712 16.464 0 29.857-13.329 29.857-29.712 0-16.384-13.393-29.713-29.857-29.713M97.242 22.569v-5.377H79.456v26.68h17.786v-5.394h-11.3v-5.396h8.903v-5.257h-8.902V22.57zm19.692-6.59v26.68h16.516v-5.515h-10.12V15.979zm35.573 1.213v26.68h16.516v-5.516h-10.12V17.192zM21.548 41.95c.99.985 2.208 1.765 3.621 2.315 1.4.543 2.99.82 4.73.82 1.737 0 3.328-.277 4.73-.82 1.412-.55 2.632-1.328 3.627-2.313.996-.986 1.77-2.17 2.297-3.52.523-1.34.789-2.812.789-4.377v-15.65h-6.458v15.65c0 .853-.119 1.627-.351 2.304-.229.653-.555 1.212-.973 1.66-.42.45-.922.79-1.535 1.04-1.232.505-3.022.507-4.254.001a4.11 4.11 0 01-1.518-1.044 4.781 4.781 0 01-.97-1.674c-.235-.675-.353-1.45-.353-2.303V18.404h-6.457v15.651c0 1.568.265 3.04.789 4.376a10.084 10.084 0 002.286 3.519"/><path d="M29.908 53.357c-12.983 0-23.546-10.51-23.546-23.43 0-12.922 10.563-23.433 23.546-23.433 12.983 0 23.546 10.51 23.546 23.432 0 12.92-10.563 23.431-23.546 23.431m0-53.144C13.444.213.05 13.542.05 29.926c0 16.383 13.393 29.712 29.857 29.712 16.462 0 29.855-13.329 29.855-29.712C59.763 13.542 46.37.213 29.908.213"/></g></svg>
      </router-link>
    </div>
    <div class="uello-sidebar-item-list pt-5">
      <u-sidebar-item v-for="item in items" :key="item.id" :item="item" :active="isActive" />
    </div>
  </div>
</template>

<script>
import menuItems from '@/config/menuConfig';
import USidebarItem from './USidebarItem.vue';

export default {
  name: 'uello-sidebar',
  components: {
    USidebarItem,
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    items() {
      return menuItems;
    },
  },
};
</script>

<style lang="scss">
.uello-sidebar::-webkit-scrollbar {
  width: 2px;
  background: #e63b4f;
}
.uello-sidebar::-webkit-scrollbar-track {
  background: #e63b4f;
}
.uello-sidebar::-webkit-scrollbar-thumb {
  background: #e63b4f;
}
.uello-sidebar::-webkit-scrollbar-thumb:hover {
  background: #e63b4f;
}

.uello-sidebar {
  width: 62px;
  transition: width 300ms ease;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000002;

  &:hover,
  &.hovered {
    width: 266px;
  }

  .uello-logo {
    height: 45px;
    width: auto;
    transition: all 200ms ease;

    &.active {
      height: 60px;
    }
  }
}
</style>
