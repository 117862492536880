<template>
  <div class="uello-sidebar-item" @mouseenter="hovered = true" @mouseleave="hovered = false">
    <div v-if="item.submenu">
      <div
        class="btn-collapse icon-link icon-link mx-2 py-2 d-flex align-items-center justify-content-between"
        @click="toggle = !toggle"
        :class="activeClasses"
        :title="$t(`sidebarMenu.${item.i18n}`)"
      >
        <div>
          <icon :icon="item.icon" :left="active" :size="28" />
          <span class="font-weight-bold text-white" v-show="active && show"
            >{{ $t(`sidebarMenu.${item.i18n}`) }}
          </span>
        </div>
        <icon
          icon="chevron-right"
          v-if="active"
          :class="`collapse-indicator ${active && toggle ? 'rotate' : ''}`"
          :size="30"
          right
        />
      </div>
      <b-collapse v-model="toggle" class="mt-2">
        <router-link
          class="icon-link submenu ml-4 mr-2 mb-1 d-block py-2"
          active-class="icon-link-active"
          v-for="subitem in item.submenu"
          :key="subitem.id"
          :to="subitem.url"
          :title="$t(`sidebarMenu.${subitem.i18n}`)"
        >
          <span class="font-weight-bold text-white" v-show="active && show">{{
            $t(`sidebarMenu.${subitem.i18n}`)
          }}</span>
        </router-link>
      </b-collapse>
    </div>
    <router-link
      v-else
      active-class="icon-link-active"
      class="icon-link mx-2 mb-1 d-block py-2 "
      :to="item.url"
      :class="activeClasses"
      :title="$t(`sidebarMenu.${item.i18n}`)"
    >
      <icon :icon="item.icon" :left="active" :size="28" />
      <span class="font-weight-bold text-white" v-show="active && show">{{
        $t(`sidebarMenu.${item.i18n}`)
      }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'uello-sidebar-item',
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      hovered: false,
      toggle: false,
    };
  },
  computed: {
    activeClasses() {
      return this.hovered ? 'bg-overlay rounded' : '';
    },
  },
  watch: {
    active(val) {
      if (val) {
        setTimeout(() => {
          this.show = val;
        }, 150);
      } else {
        this.toggle = false;
        this.show = false;
      }
    },
  },
};
</script>

<style lang="scss">
.uello-sidebar-item {
  .icon-link {
    padding-left: 8px;
    text-decoration: none;
    transition: all 200ms ease;

    &.submenu:hover {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 0.2rem;
    }

    &.icon-link-active {
      border-radius: 0.2rem;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .bg-overlay {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .icon-link-active {
    border-radius: 0.2rem;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .btn-collapse {
    cursor: pointer;

    &.icon-link-active {
      border-radius: 0.2rem;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .collapse-indicator {
    svg {
      transition: all 200ms linear;
    }

    &.rotate {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
