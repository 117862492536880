export default [
  {
    id: 'uello-sidebar-item-0',
    url: '/app/map',
    name: 'Map',
    icon: 'map',
    i18n: 'map',
  },
  {
    id: 'uello-sidebar-item-2',
    url: '/app/request',
    name: 'Request',
    icon: 'file-send',
    i18n: 'request',
  },
  {
    id: 'uello-sidebar-item-2-5',
    url: '/app/favorite-addresses',
    name: 'Favorite Addresses',
    icon: 'star-outline',
    i18n: 'favoriteAddresses',
  },
  {
    id: 'uello-sidebar-item-3',
    url: '/app/follow',
    name: 'Follow',
    icon: 'file-document-box-search',
    i18n: 'follow',
  },
  {
    id: 'uello-sidebar-item-4',
    url: '/app/history/orders',
    slug: 'historic-orders',
    icon: 'history',
    name: 'Orders',
    i18n: 'requests',
  },
  {
    id: 'uello-sidebar-item-5',
    url: '/app/report',
    slug: 'report',
    icon: 'chart-bar',
    name: 'Reports',
    i18n: 'reports',
  },
  {
    id: 'uello-sidebar-item-6',
    url: '/app/users-management',
    name: 'AccountGroup',
    icon: 'account-group',
    i18n: 'management',
  },
  {
    id: 'uello-sidebar-item-7',
    url: '/app/finances',
    name: 'Finances',
    icon: 'currency-usd',
    i18n: 'finances',
  },
  {
    id: 'uello-sidebar-item-8',
    url: '/app/faq',
    name: 'FAQ',
    icon: 'account-question-outline',
    i18n: 'faq',
  },
];
