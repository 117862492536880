<template>
  <div class="uello-app">
    <u-sidebar />
    <u-header />
    <div class="route-container bg-white" :style="{ height }">
      <transition name="slide" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { widthMixin, centralWindowSize } from '@/mixins';
import USidebar from './components/u-sidebax/USidebar.vue';
import UHeader from './components/u-header/UHeader.vue';

export default {
  name: 'uello-app',
  mixins: [widthMixin, centralWindowSize],
  components: {
    USidebar,
    UHeader,
  },
  data() {
    return {
      height: `100px`,
    };
  },
  watch: {
    windowHeight() {
      this.height = '100vh';
    },
  },
};
</script>

<style lang="scss">
.uello-app {
  .route-container {
    margin-left: 62px;
    /* overflow: hidden; */
    overflow-y: scroll;
  }
}
</style>
